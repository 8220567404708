<template>
  <div class="deductionSequence">
    <mt-header :title="$t('deductionSequence')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <div class="tips">
        {{ $t('deductionSequenceTips') }}
        <span>{{ $t('longPressDrag') }}</span>
      </div>
      <draggable v-if="list.length" v-model="list" @end="onEnd">
        <mt-cell v-for="(item, index) in list" :key="index" :title="item.bank + ' ****' + item.last4">
          <img slot="icon" src="../../../assets/logo.png" height="22px" style="margin: 0px 10px 0px 8px;">
          <img src="../../../assets/sequence.png" width="19px">
        </mt-cell>
      </draggable>
    </div>
  </div>
</template>

<script>
import { bindCardList, adjustCardSort } from '../../../utils/api'
import callPhone from '@/components/callPhone.vue'
import { mapState, mapMutations } from "vuex";
export default {
  name: 'DeductionSequence',
  components: {
    callPhone
  },
  data () {
    return {
      list: [],
    }
  },
  computed: {
    ...mapState(["bankCard"]),
  },
  watch: {
    bankCard: {
      handler(val) {
        this.list = val.concat([]).sort((a, b) => a.sort - b.sort)
      },
      immediate: true,
    }
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations(['SET_BANK_CARD']),
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      })
      await this.getBankCard()
      this.$indicator.close()
    },
    async getBankCard() {
      await this.$axios({
        method: 'post',
        url: bindCardList,
      })
        .then((e) => {
          if (e.status.code == "000") {
            this.SET_BANK_CARD(e.body)
          }
        })
        .catch(() => {})
    },
    async onEnd() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      })
      await this.$axios({
        method: 'post',
        url: adjustCardSort,
        data: this.list.map((e, i) => ({
          ...e,
          sort: i + 1
        }))
      })
        .then((e) => {
          if (e.status.code != "000") {
             this.$toast(e.status.msg)
          }
        })
        .catch(() => {})
      this.$indicator.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.deductionSequence {
  .content {
    padding: 22px;
    /deep/ .mint-cell-wrapper {
      padding-left: 0px;
    }
    .tips {
      margin: 15px 0px;
      font-size: 12px;
      color: #393939;
      line-height: 18px;
      span {
        font-size: 13px;
        color: #B0B0B0;
      }
    }
  }
}
</style>